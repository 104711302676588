if (!wahanda) var wahanda = {};
if (!wahanda.signup) wahanda.signup = {};

wahanda.signup.postSignupPopup = function(params) {

    // define variables based on the parameters
    var email = params.email;
    var popupHtml = params.html;
    var redirectOnClose = params.redirectOnClose || false;
    var redirectUrl = params.redirectUrl || false;
    var redirect = redirectUrl ? true : params.redirect || false;

    var otherSourceId = 7;
    var confirmationDialog = $("<div id='marketing-source-containerX' style='display:none;'></div>");
    var accountSourcePopup;
    var signupTargetUri = $('#marketing-source-popup').data('target-uri');
    var rootUri = window.location.protocol + "//" + window.location.host;

    $(confirmationDialog).appendTo('body');
    $(popupHtml).appendTo(confirmationDialog);

    // determine signup target uri from URL if not found in target-uri data tag
    if (typeof signupTargetUri === "undefined") {

        // this should be placed in WAHANDA global object eventually
        function match(name) {
            var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
            return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        }

        signupTargetUri = match("target-uri");

        if (!signupTargetUri) signupTargetUri = redirectUrl;

        if (!signupTargetUri) signupTargetUri = false;
    }

    (function() {
        var accountSourcePopup = new wahanda.Popup('#marketing-source-popup', {
            width: 300,
            hideCloseButton: true,
            beforeClose: function() {

                // if specified to redirect on dialog close
                if (redirectOnClose) {
                    // redirect to the signupTargetUri or site root if it wasnt't provided
                    window.location.href = signupTargetUri || rootUri;
                }
            }
        });
        var _dialog = accountSourcePopup.dialog;
        var _form = $("#marketing-source-form", _dialog);
        var _sourceId = $('#marketing-source-id', _dialog);

        if (!accountSourcePopup.dialogElement.data('show')) {
            finished();
            return;
        }

        _form.validate({
            blur: true,
            rules: {
                'notes': {
                    required: function() {
                        return _sourceId.val() == otherSourceId;
                    }
                }
            },
            errorClass: "error",
            submitHandler: function() {
                finished();
            }
          });

        accountSourcePopup.open(function() {
            if (_sourceId.length) {
                // new user: bind events
                _sourceId.bind("change keypress", function() {
                    var action = $(this).val() == otherSourceId ? "show" : "hide";
                    $('.notes', _dialog)[action]().valid();
                });
            } else {
                // existing user: redirect to target uri
                window.location.href = signupTargetUri;
            }
        });

        function finished() {
            // if a value was provided for the marketing source
            if (_sourceId.val()) {

                // submit marketing source
                _form.ajaxSubmit(function(data) {
                    if ($('status', data).text() === 'OKAY' && redirect) {

                        // take user to home page  if no uri found
                        if (signupTargetUri == false) {
                            signupTargetUri = rootUri;
                        }

                        newsletterSignupFinished(signupTargetUri);

                        // redirect to determined location
                        window.location.href = signupTargetUri;
                    } else {
                        accountSourcePopup.close();
                    }
                });
            } else {
                // go to target uri if provided
                if (signupTargetUri) {
                    window.location.href = signupTargetUri;
                }
            }
        }
    })();
};
