(function($) {
    $.widget("custom.wahandadropdown", {
        options: {
            // Selector for the parent element to toggle "used" class
            parentContainerSelector: ".form-row",
            // Wahanda Dropdown div selector. Toggles on/off classes.
            dropdownSelector       : ".wahanda-dropdown, .wahanda-dropdown-small",
            // Whether the "on" class" is maintained on the container element.
            onClassManagedOnContainer: true
        },

         _create: function() {
             var self = this;
             var $input  = this.element;
             var closeDatePicker = this.closeDatepickers;
             var openEvent;
             if (this.options.count) {
                 this.options.format = function(text, opt) {
                     var count = "";
                     if (opt.data('count')) {
                         count = opt.data('count');
                     }
                     text = "<span class='value'>"+text+"</span><span class='amount'>" + count + "</span>" + text;
                     return text
                 }
             }

             if (this.options.openEvent) {
                 openEvent = this.options.openEvent;
             }

             $input.selectmenu($.extend(
                 {
                     open: function() {
                         // The menu's default z-index is sometimes not quite high enough to obscure
                         // other page elements.
                         var zIndex = (parseInt(self.$widget.css('z-index')) || 1) + 10;
                         self.$widget.css('z-index', zIndex);

                         closeDatePicker();
                         if (openEvent) {
                             openEvent();
                         }
                     }
                 },
                 this.options
             ));

             this.$widget = $input.selectmenu("widget");
             this.selectMenu = this.$widget.find("a.ui-selectmenu");
             this.selectMenuSelected = this.$widget.find(".ui-selectmenu-status");
             this.selectMenuDropDown = this.$widget.find('.ui-selectmenu-menu-dropdown');
             this.element.closest(this.options.dropdownSelector)
                 .addClass('loaded')
                 .find('.input-icon').show();
             this.addDefaultClasses();
             this.addOptionalClasses();
             this._bindEvents();
             this.tracking = this.options.tracking || {};
         },

         addDefaultClasses: function() {
             this.selectMenuSelected.addClass("selected");
             this.selectMenuDropDown.addClass("dropdown-menu");
             this.selectMenuDropDown.addClass("ui-menu");
         },

         addOptionalClasses: function() {
             if (this.options.dropDownClass) {
                 this.selectMenu.addClass(this.options.dropDownClass);
             }
         },

         // For some reason, opening a selectmenu does not close any open datepickers.
         // This is true even for a native selectmenu.
         //
         // This shouldn't be necessary, and isn't elegant, but it works.
         closeDatepickers: function() {
             $.datepicker._hideDatepicker();
             if ($('input.hasDatepicker').is(":focus")) {
                 $('input.hasDatepicker').blur()
             }
         },

         refresh: function() {
             this.element.selectmenu();
         },

         _bindEvents: function() {
             var selector     = this.options.parentContainerSelector;
             var $elem        = this.element;
             var $dropdownDiv = $elem.closest(this.options.dropdownSelector);
             var $selectMenu  = this.$widget.find(".ui-selectmenu");
             var isOpen = false;
             var isClosing = false;
             var self = this;
             var containerClasses = "used" + (this.options.onClassManagedOnContainer ? " on" : "");
             $dropdownDiv.find('.input-icon').on('click', function() {
                 if (isOpen) {
                     $elem.selectmenu('close');
                     isOpen = false;
                 } else {
                     $elem.selectmenu('open');
                     isOpen = true;
                 }
             });

              $dropdownDiv.on("click", function () {
                  if (!isOpen && !isClosing) {
                      isOpen = true;
                      $elem.selectmenu("open");
                  }
              });
              $elem.on("selectmenuclose", function () {
                  isOpen = false;
                  isClosing = true;

                  setTimeout(function () {
                      isClosing = false;
                  }, 0);
              });

             $elem
                 .on('selectmenuopen', function() {
                     $elem.closest(selector).addClass(containerClasses);
                     $dropdownDiv.find('ul').width($dropdownDiv.width());
                     $dropdownDiv.removeClass('off').addClass('on');

                     if (self.tracking.open) {
                         self.tracking.open();
                     }
                 })
                 .on('selectmenuclose', function() {
                     if (!$selectMenu.is(":focus")) {
                         $elem.closest(selector).removeClass(containerClasses);
                         $dropdownDiv.removeClass('on').addClass('off');
                     }

                     if (self.tracking.close) {
                        self.tracking.close();
                     }
                 })
                 .on('change', function() {
                     // Play nice with jquery validate plugin.
                     if ($elem.parents("form").length && typeof $elem.valid === 'function') {
                         $elem.valid();
                     }

                     if (self.tracking.change) {
                         self.tracking.change($(this).find("option:selected"))
                     }
                 });

             $selectMenu.on("focus.selectmenu", function() {
                 $elem.closest(selector).addClass(containerClasses);
                 $dropdownDiv.removeClass('off').addClass('on');
             });
             $selectMenu.on("blur.selectmenu", function() {
                 $elem.closest(selector).removeClass(containerClasses);
                 $dropdownDiv.removeClass('on').addClass('off');
             });
         }
     });
}(jQuery));
