/*
=====================================================
Wahanda.com
Global JavaScript functions library
=====================================================
*/

// Check required dynamic globals (e.g. cms) have been set
["boxy-loading-message", "mediaDomain", "googleAnalyticsEnabled"].forEach((item) => {
  if (!window.wahanda[item]) {
    throw new Error(`${item} not found on wahanda global`);
  }
});

// Create wahanda.* objects.
(function () {
  var wahandaObjects = ["_dump", "browse", "core", "ui", "utils"];

  for (var i in wahandaObjects) {
    wahanda[wahandaObjects[i]] = {};
  }
}());

require("./popup");
require("./post-signup-popup");
require("./wahandadropdown");

window.loadingSpinner = $(`<div id="boxy-ajax-loading"><img src="https://${wahanda.mediaDomain}/images/static/boxy-ajax-loader.gif" height="32" width="32" alt="Loading" /><p>${wahanda["boxy-loading-message"]}</p></div>`);

window.wlog = function wlog (string) {
  if ("$content.javascript.site.enable-logging" === "true") {
    try {
      console.log(string);
    } catch (e) {}
  }
};

// Clear/set the 'disabled=true' attribute on an element (typically a form input)
// e.g. to enable/disable all inputs inside 'form-section' iff 'control' is checked:
//  $("#form #form-section :input").enable($("#form #control:checked").length > 0)
jQuery.fn.extend({
  enable (enabled) {
    if (enabled) {
      $(this).removeAttr("disabled");
    } else {
      $(this).attr("disabled", "true");
    }
  }
});

// ------------- jQuery Validator Checks ----------------------

require("./jquery-validator-rules");

// core functions used in many places within the site
wahanda.core = {
  init () {
        // initialize UI
    wahanda.ui.init();
  }
};

wahanda.ui = {
  init () {
        // form toggles
    jQuery(".toggle").click(function (e) {
      e.preventDefault();
      var _this = jQuery(this);
      jQuery(_this.attr("data-toggle-ref"))[_this.attr("data-toggle-type") == "slide" ? "slideToggle" : "toggle"](200);
    });

        // accordions;
    this.accordion();

    jQuery(".accordion").accordion({
      collapsible: true,
      openFirst: false
    });

        // initialize scroll to plugin
    this.scrollTo();
  },

  modalBackground () {
    var modalBg = $('<div id="modal-background"></div>')
            .css({
              position: "fixed",
              "background-color": "white",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
              "z-index": 10000,
              opacity: 0.6
            })
            .hide()
            .appendTo("body");
    return modalBg;
  },

  accountActionsMenu () {
    var _parent = this;
    $("#account-actions-wrapper").on({
      "mouseover" () {
        $("#account-actions").addClass("hover");
        $("#account-actions-menu").show();
      },
      mouseout () {
        $("#account-actions-menu").hide();
        $("#account-actions").removeClass("hover");
      }
    });
  },

  accordion: require("./accordion"),

  scrollTo () {
        /* HOW TO USE
            $(element-i-want-to-scroll-to).scrollTo(offset-from-element, at-this-speed);
        */

    $.fn.scrollTo = function (offset, speed) {
      var top = this.offset().top;
      var offset = offset ? top + offset : top;
      var speed = speed ? speed : 400;

      return this.each(function () {
        $("html, body").stop().animate({
          scrollTop: offset
        }, speed);
      });
    };
    return this;
  }
};

$(document).ready(function () {
    // initialize wahanda functions
  wahanda.core.init();

  $("input.clickclear, textarea.clickclear").hint({ attr: "title", keepLabel: true });

  $("a.login").click(function () {
    window.location = "/protected-action?route=" + encodeURI(window.location);
    return false;
  });

  $("a.logout").on("click", function (e) {
    e.preventDefault();
    logout();
    return false;
  });

    // make external links open in new window/tab
  $("a.externallink").live("click", function () {
    var target = $(this).attr("href");
    if (!(/^mailto:/.test(target))) {
      window.open(target);
      return false;
    } else {
      return true;
    }
  });

    // check whether user is logged in.



    // ----------------------------------------------------------------------
    // SEARCH BOX FUNCTIONS
    // ----------------------------------------------------------------------

  $("fieldset.voucher-module").click(function () {
    $(this).find(".voucher-input").click();
  });

    // ----------------------------------------------------------------------
    // HELP POPUPS
    // ----------------------------------------------------------------------
  bindHelpLinkPopup();

  $("a.faq-popup, a.faq-link").click(function () {
    var link = this;

    jQuery.ajax({
      type: "get",
      url: $(this).attr("href"),
      dataType: "html",
      success (data) {
        $(data).dialog({ title: $(link).attr("title"), modal: true });
      }
    });
    return false;
  });

  $(loadingSpinner).dialog({
    autoOpen: false,
    bgiframe: true,
    resizable: false,
    modal: true
  });

  $(document).trigger("late-initialisation");
});
// END OF $(document).ready(function()

// ----------------------------------------------------------------------
// HELP POPUPS
// ----------------------------------------------------------------------
window.bindHelpLinkPopup = function bindHelpLinkPopup () {
  $("span.help-popup, span.help-link, span.help").live("click", function (e) {
    var link = $(this);

    jQuery.ajax({
      type: "get",
      url: link.attr("href"),
      dataType: "html",
      success (data) {
        new wahanda.Popup(data, {
          width: 300,
          minHeight: "auto",
          autoOpen: true
        });
      }
    });
  });

  $("a.help-wide-link").click(function () {
    var link = this;
    jQuery.ajax({
      type: "get",
      url: $(this).attr("href"),
      dataType: "html",
      success (data) {
        $(data).dialog({
          title: $(link).attr("title"),
          modal: true,
          width: 600,
          buttons: { "Close Help" () { $(this).dialog("close"); } }
        });
      }
    });
    return false;
  });
};

// ----------------------------------------------------------------------
// MAP POPUP
// ----------------------------------------------------------------------

// Because of the Google maps/jquery conflict bug (see below),
// this flag is used to stop triggering multiple popup maps.

window.launchPopupMap = true;

window.loadPopupMap = function loadPopupMap (mapPopupUri, popupName) {
  if (!launchPopupMap) {
    return false;
  }
  var mapPopup = $("<div id='map-popup'></div>");
  $(mapPopup).dialog({
        // Conflict between Jquery UI Dialogs and Google Maps V2 'largeMapControls' slider
        // http://stackoverflow.com/questions/3957510/how-do-i-fix-drag-and-drop-for-a-google-map-v2-inside-a-jquery-dialog-1-4-2-1
        // modal: true, // http://stackoverflow.com/questions/3957510/how-do-i-fix-drag-and-drop-for-a-google-map-v2-inside-a-jquery-dialog-1-4-2-1
    modal: true, // Cannot reproduce the bug
    title: popupName,
    width: 600,
    height: 600,
    resizable: false,
    dialogClass: "wahanda-popup",
    open (event, ui) {
      launchPopupMap = false;
      jQuery.ajax({
        type: "GET",
        url: mapPopupUri,
        success (data) {
          $(mapPopup).html(data);
        },
        error () {alert("Could not load the requested map.");}
      });
    },
    close () {
      launchPopupMap = true;
      $(this).remove();
    }
  });
  $(mapPopup).dialog("open");
};

// ----------------------------------------------------------------------
// AUTHENTICATION/REGISTRATION
// ----------------------------------------------------------------------

window.newsletterSignupFinished = function newsletterSignupFinished (redirectUri) {
    // Set the signup cookie to prevent the topbar showing
  setNewsletterTrackerCookieValue("done");

  if (!redirectUri) {
    redirectUri = $("#wahanda-logo").attr("href");
  }
  if (!redirectUri) {
    redirectUri = "/";
  }

  window.location = redirectUri;
};

// logout of site. This does not affect any external authentication, just the local
// authentication cookies. Pages that require to do additional changes, such are redirecting
// away from the current page, should implement the pageLogoutAction function
window.logout = function logout () {
  jQuery.post("/ajax/site/logout", function (data) {
        // some pages explicitly set up their own logout handler
    if (typeof pageLogoutAction === "function") {
      pageLogoutAction();
    } else {
      window.location.reload();
    }
  });
  return false;
};

// ----------------------------------------------------------------------
// Contextual login/register
// ----------------------------------------------------------------------

window.limitChars = function limitChars (textid, limit, infodiv) {
  var text = $("#" + textid).val();
  var textlength = text.length;

  if (textlength > limit) {
    $("#" + infodiv).html("<strong>You cannot write more then " + limit + " characters!<\/strong>");
    $("#" + textid).val(text.substr(0, limit));
    return false;
  } else {
    $("#" + infodiv).html("You have <strong>" + (limit - textlength) + "<\/strong> characters left.");
    return true;
  }
};

require("./newsletter-tracking");

// stop downloads, as if clicking on the "stop" button
window.stopDownloads = function stopDownloads () {
  if (window.stop !== undefined) {
    window.stop();
  } else if (document.execCommand !== undefined) {
    document.execCommand("Stop", false);
  }
};

// stop downloads and change location: e.g. google maps resource may still be downloaded after simply changing location.
// particularly important when moving onto ssl, since resources still in transit arriving after the location has changed
// may cause the ssl page to be marked as having secure/insecure mixed content.
window.changeLocation = function changeLocation (loc) {
  window.stopDownloads();
  window.location = loc;
};
