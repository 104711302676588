/*
 * Markup is expected to be like this.
 *
 * <... class="accordion">
 *   <...></...>   	  <!-- first title -->
 *   <...></...>   	  <!-- first content -->
 *   <...></...>   	  <!-- second title -->
 *   <...></...>   	  <!-- second content -->
 *   ...
 * </...>
 */
module.exports = function () {
  jQuery.fn.accordion = function (methodOrOptions) {
    var options;

    var methods = {
      init (initOptions) {
        options = jQuery.extend({
          collapsible: false,
          openFirst: true,
          onClose: false,
          onOpen: false
        }, initOptions);

        var collapsible = options.collapsible,
          onClose = typeof options.onClose === "function" ? options.onClose : false,
          onOpen = typeof options.onOpen === "function" ? options.onOpen : false;

        return this.each(function () {
          var _this = jQuery(this),
            _children = _this.children(),
            _first = false,
            titleTag = "";

          if (_children.length > 0) {
            titleTag = _children.eq(0)[0].tagName;
          }

          _children.each(function (i, el) {
            if (el.tagName === titleTag) {
              if (!_first) {_first = jQuery(el);}

              var container = _children[i + 1];
              if (container) {
                var _container = jQuery(container).addClass("acc-content"),
                  _el = jQuery(el),
                  action,
                  callback;

                _el.addClass("acc-title");

                _el.click(function (e) {
                  e.preventDefault();

                                    // is accordion collapsible & visible?
                  if (collapsible && _container.is(":visible")) {
                    action = "slideUp";
                    if (onClose) {
                      callback = onClose(_this, _el, _container);
                    }
                    _el.add(_container)
                                            .removeClass("acc-selected");
                  } else {
                    action = "slideDown";
                    if (onOpen) {
                      callback = onOpen(_this, _el, _container);
                    }
                    _el.add(_container)
                                           .addClass("acc-selected");
                  }

                  _container[action](400, callback);
                });
              }
            }
          });

                    // open the first container by default (a bit hacky... should really use click method)
          if (options.openFirst && _first) {
            _first.addClass("acc-title acc-selected").next().addClass("acc-selected").show();
          }
        });
      },

      openFirst () {
        $(".acc-title:first", this).click();
      }
    };

    if (methods[methodOrOptions]) {
      return methods[ methodOrOptions ].apply(this, Array.prototype.slice.call(arguments, 1));
    } else if (typeof methodOrOptions === "object" || !methodOrOptions) {
            // Default to "init"
      return methods.init.apply(this, arguments);
    } else {
      $.error("Method " + method + " does not exist on jQuery.accordion");
    }
  };
};
