jQuery.validator.addMethod("messagelength", function (value, element, param) {
    // The default jQuery validator 'field length' method
    // Doesn't count spaces, (possibly rightfully).
    // This method does.
  if (value.length > param) {return false;}
  return true;
}, "You need to shorten this message.");

jQuery.validator.addMethod("notequalto", function (value, element, param) {
  return this.optional(element) || value != $(param).val();
}, "Values cannot be the same");

jQuery.validator.addMethod("username", function (value, element) {
  var re = new RegExp("^[a-zA-Z0-9\x2D]+$");
  return this.optional(element) || re.test(value);
}, "Username can consist of letters, numbers and '-' only.");

jQuery.validator.addMethod("bankaccount", function (value, element) {
  var re = new RegExp("^[0-9\x2D]+$");
  return this.optional(element) || re.test(value);
}, "Bank account can consist of numbers and '-' only.");

jQuery.validator.addMethod("vouchercode", function (value, element) {
  var re = new RegExp("^[0-9]+(-[0-9]+-[0-9]+)?$");
  return this.optional(element) || re.test(value);
}, "digits and '-' only.");

jQuery.validator.addMethod("treatmentList", function (value, element, param) {
  $("option", element).prop("selected", true);
  if (element.length < 1 || element.length > param) {
    return false;
  }
  return true;
}, jQuery.format("Please select between 1 and {0} treatments"));

jQuery.validator.addMethod("emailList", function (value, element, param) {
  var addresses = value.split(/ *[, ]+/);
  var overallResult = true;
  for (var i in addresses) {
    var thisResult = (/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i).test(addresses[i]);
    if (!thisResult) {
      wlog("invalid email: " + addresses[i]);
      overallResult = false;
    }
  }
  return overallResult;
}, "Must be comma-separated email addresses");

jQuery.validator.addMethod("postZipCode", function (value, element) {
    // UK Postcode regex: http://regexlib.com/REDetails.aspx?regexp_id=260&AspxAutoDetectCookieSupport=1 (adapted to remove second half of postcode)
    // US/Canadian ZIP code regex: http://www.itsalif.info/content/canada-postal-code-us-zip-code-regex
  return (/^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]?|GIR 0AA|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$)|(^\d{5}(-\d{4})?)$/).test(value.toUpperCase());
}, "Please enter a valid UK, US or Canadian postcode");

// To default to the start of the current month

window.firstOfTheMonth = function firstOfTheMonth (date) {
  date.setDate(1);
  date.setHours(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setMilliseconds(0);

  return date;
};

// date comparison - return false if the second date is later than the first
jQuery.validator.addMethod("datecompare", function (value, element, param) {
  if (this.optional(element)) {
    return true;
  }

  var paramValue = $(param).val();

    // if either of the values are not set, it is valid
  if (value == null || paramValue == null) {
    return true;
  }

  var date1 = new Date(value);
  var date2 = new Date(paramValue);

  return date2 <= date1;
}, "The second date is later than the first");

jQuery.validator.addMethod("mmyyyyGreaterThan", function (value, element, param) {
  var month1 = param.first.month.val(),
    year1 = param.first.year.val(),
    month2 = param.second.month.val(),
    year2 = param.second.year.val(),
    date1,
    date2;

  if (this.optional(element)) {
    return true;
  }

  if (month1 && year1) {
    date1 = new Date(year1, (month1 - 1), 1);
    date2 = new Date(year2, (month2 - 1), 1);
    if (date1 == date2) {
      return true;
    } else if (date1 > date2) {
      return false;
    }
  }

  return true;
}, "The first date is later than the second");


jQuery.validator.addMethod("mmyyyyFromThePast", function (value, element, param) {
  var month = param.month.val(),
    year = param.year.val(),
    currentDate = firstOfTheMonth(new Date()),
    date;

  if (this.optional(element)) {
    return true;
  }

  if (month && year) {
    date = firstOfTheMonth(new Date(year, (month - 1), 1));
    if (currentDate > date) {
      return false;
    }
  }
  return true;
}, "This is a date from the past");

jQuery.validator.addMethod("mmyyyyInTheFuture", function (value, element, param) {
  var month = param.month.val(),
    year = param.year.val(),
    currentDate = firstOfTheMonth(new Date()),
    date;

  if (this.optional(element)) {
    return true;
  }

  if (month && year) {
    date = firstOfTheMonth(new Date(year, (month - 1), 1));
    if (date > currentDate && date != currentDate) {
      return false;
    }
  }

  return true;
}, "This is a date in the future");


jQuery.validator.addMethod("greaterThan", function (value, element, param) {
  return this.optional(element) || parseInt(value, 10) > parseInt($(param).val(), 10);
}, "The first value must be greater than the second");
jQuery.validator.addMethod("lessThan", function (value, element, param) {
  return this.optional(element) || parseInt(value, 10) < parseInt($(param).val(), 10);
}, "The first value must be less than the second");
jQuery.validator.addMethod("greaterOrEqualTo", function (value, element, param) {
  return this.optional(element) || parseInt(value, 10) >= parseInt($(param).val(), 10);
}, "The first value must be greater than or equal to the second");
jQuery.validator.addMethod("lessThanOrEqualTo", function (value, element, param) {
  return this.optional(element) || parseInt(value, 10) <= parseInt($(param).val(), 10);
}, "The first value must be less than or equal to the second");
jQuery.validator.addMethod("setRetailPrice", function (value, element, param) {
  return this.optional(element) || parseInt(value, 10) > parseInt($(param).val(), 10);
}, "The RRP must be higher than the price set.");

// http://stackoverflow.com/questions/1300994/jquery-validate-require-at-least-one-field-in-a-group-to-be-filled
jQuery.validator.addMethod("requireFromGroup", function (value, element, options) {
  var numberRequired = options[0];
  var selector = options[1];
    // Look for our selector within the parent form
  var validOrNot = $(selector, element.form).filter(function () {
         // Each field is kept if it has a value
    return $(this).val();
         // Set to true if there are enough, else to false
  }).length >= numberRequired;

    // The elegent part - this element needs to check the others that match the
    // selector, but we don't want to set off a feedback loop where all the
    // elements check all the others which check all the others which
    // check all the others...
    // So instead we
    //  1) Flag all matching elements as 'currently being validated'
    //  using jQuery's .data()
    //  2) Re-run validation on each of them. Since the others are now
    //     flagged as being in the process, they will skip this section,
    //     and therefore won't turn around and validate everything else
    //  3) Once that's done, we remove the 'currently being validated' flag
    //     from all the elements
  if (!$(element).data("being_validated")) {
    var fields = $(selector, element.form);
    fields.data("being_validated", true);
    // .valid() means "validate using all applicable rules" (which
    // includes this one)
    fields.valid();
    fields.data("being_validated", false);
  }
  return validOrNot;
    // {0} below is the 0th item in the options field
}, jQuery.format("Please fill out at least {0} of these fields."));
