/**
 * Create a Wahanda style popup.
 *
 * @param selector           a string with a selector for finding the popup's root element.
 * @param optionsOverrides   an object whose properties are jQueryUI dialog options.
 *                           The properties will supplement of override the default options for a Wahanda popup.
 *
 *                           Wahanda popup specific options:
 *                             notTitlebar         If truthy, the title bar will be hidden.
 *                             hideCloseButton     If truthy, the close (round X) button will be hidden.
 *                             ignoreOverlayClick  If truthy, the popup cannot be dismissed by clicking on the overlay.
 */
wahanda.Popup = function(selector, optionsOverrides) {
    var self = this;
    var options;
    var bodyOverflow;

    this.dialogElement = $(selector);

    optionsOverrides = optionsOverrides || {};

    if (this.dialogElement.data("title")) {
        optionsOverrides.title = this.dialogElement.data("title");
    }

    options = {
        autoOpen: false,
        modal:true,
        draggable: false,
        dialogClass: 'wahanda-popup',
        resizable: false,
        width: this.dialogElement.width(),
        open: function(event, ui) {
            bodyOverflow = $("body").css("overflow");
            $("body").css("overflow", "hidden");

            if (optionsOverrides.hideCloseButton) {
                $(this).parent().find('.ui-dialog-titlebar-close').hide();
            }

            if (optionsOverrides.noTitlebar) {
                $(this).parent().find(".ui-dialog-titlebar").hide()
            }
        },
        beforeClose: function(event, ui) {
            // Restore any overriden overlay styles.
            if (self.oldOverlayBackgroundColor) {
                $('.ui-widget-overlay').css('background-image', '');
                $('.ui-widget-overlay').css('background-color', self.oldOverlayBackgroundColor);
            }
            if (self.oldOverlayOpacity) {
                $('.ui-widget-overlay').css('opacity', self.oldOverlayOpacity);
            }

            $("body").css("overflow", bodyOverflow);
        }
    };

    if (optionsOverrides.useElementDimensions) {
        options.height = this.dialogElement.height();
    }

    // Merge overrides in to options.
    for (var properyName in optionsOverrides) {
        options[properyName] = optionsOverrides[properyName];
    }

    this.dialog = this.dialogElement.dialog(options);
    this.optionsOverrides = optionsOverrides;
};

wahanda.Popup.prototype.open = function (callback) {
    var self = this;

    this.dialog.dialog('open');
    if (this.dialogElement.find(".popup-content").length > 0) {
        this.dialogElement.find(".popup-content")[0].scrollTop = 0;
    }
    // Dismiss the dialog if the modal dialog's translucent overlay is clicked.
    // Although not exactly a documented approach, this is unlikely to break, even in future
    // versions of jQuery UI.
    if (!this.optionsOverrides.ignoreOverlayClick) {
        $('.ui-widget-overlay').click(function() {
            self.dialog.dialog('close');
        });
    }

    // Optionally use an image for the overlay (instead of a tranlucent overlay).
    if (this.optionsOverrides.overlayBackgroundImage) {
        // Preserve overlay styles.
        this.oldOverlayBackgroundColor = $('.ui-widget-overlay').css('background-color');
        this.oldOverlayOpacity = $('.ui-widget-overlay').css('opacity');

        // Use the supplied image for the overlay.
        $('.ui-widget-overlay').css('background-color', '');
        $('.ui-widget-overlay').css('background-image', 'url(' + this.optionsOverrides.overlayBackgroundImage + ')');
        $('.ui-widget-overlay').css('opacity', '1');
    }

    if (typeof callback == "function") {
        callback();
    }
};

wahanda.Popup.prototype.close = function () {
    this.dialog.dialog('close');
};
